import { http } from '@/http/axios.js'

export const MENU_TYPE = [
  { name: '目录', value: 1 },
  { name: '菜单', value: 2 },
  { name: '按钮', value: 3 }
]

// 同步常量
export const SYNC_ZUJI = 'ok_zuji'
export function getDictEnumListAPI(dictCode) {
  return http({
    url: '/admin/sys/dictEnum/list',
    method: 'get',
    params: { dictCode }
  }).then(res => {
    return res.map(item => {
      return {
        name: item.enumText,
        value: item.enumCode
      }
    })
  })
}
export const stockType = [
  { name: '入库', value: 1 },
  { name: '出库', value: 2 }
]
export const genderDict = [
  { name: '男', value: 1 },
  { name: '女', value: 0 }
]
export const targetSideDict = [
  { name: '外部', value: 2 },
  { name: '内部', value: 1 }
]
export const targetTypeDict = [
  { name: '小程序', value: 1 },
  { name: 'h5', value: 2 }
]



// 渠道管理
export const channelOrderStatus = [
  { name: '审核中', value: '1' },
  { name: '待支付', value: '2' },
  { name: '待签章', value: '3' },
  { name: '待发货', value: '4' },
  { name: '待收货', value: '5' },
  { name: '租赁中', value: '6' },
  { name: '逾期中', value: '7' },
  { name: '待买断', value: '8' },
  { name: '待归还', value: '9' },
  { name: '已完成', value: '91' },
  { name: '已取消', value: '92' },
  { name: '已拒绝', value: '93' }
]
export const channelSearchKw = [
  { name: '用户名', value: 'memberName' },
  { name: '手机号', value: 'memberMobile' },
  { name: '订单号', value: 'orderNo' },
  // { name: '订单渠道', value: 'orderChannelName' }
]
export const memberType = [
  { name: '新客', value: '1' },
  { name: '在租', value: '2' }
]
export const isRegister = [
  { name: '已注册', value: '1' },
  { name: '新注册', value: '0' }
]
