<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="90px">
        <el-form-item label="关键字" class="keywords">
          <el-input placeholder="请输入" v-model="searchData.keywordValue" class="input-with-select" size="small">
            <el-select v-model="searchData.keywordName" slot="prepend" placeholder="请选择" size="small">
              <el-option v-for="item in channelSearchKw" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="订单状态" class="item">
          <el-select v-model="searchData.orderStatus" placeholder="请选择" size="small" multiple clearable>
            <el-option v-for="item in channelOrderStatus" :key="item.value" :label="item.name" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单时间" class="item">
          <el-date-picker
              style="width: 280px"
              v-model="searchData.timeSlot"
              size="small"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="是否免押" class="item">
          <el-select v-model="searchData.enableAlipayFundAuth" placeholder="请选择" size="small" clearable>
            <el-option label="是" :value="true"> </el-option>
            <el-option label="否" :value="false"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否在租" class="item">
          <el-select v-model="searchData.memberType" placeholder="请选择" size="small">
            <el-option v-for="item in memberType" :key="item.value" :label="item.name" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否注册" class="item">
          <el-select v-model="searchData.isRegister" placeholder="请选择" size="small">
            <el-option v-for="item in isRegister" :key="item.value" :label="item.name" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
  </div>
</template>

<script>
import { getListAPI } from './api'
import { channelSearchKw, channelOrderStatus, memberType, isRegister } from '@/enum/dict.js'
import { authBtnMixin } from '@/mixins/authBtnMixin.js'
const columns = [
  {
    label: '用户名',
    prop: 'member',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['member']?.['memberName']}</p>
    }
  },
  {
    label: '手机号',
    prop: 'member',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['member']?.['mobile']}</p>
    }
  },
  {
    label: '订单号',
    prop: 'order',
    minWidth: '220',
    customRender(h, row) {
      return <p>{row['order']?.['orderNo']}</p>
    }
  },
  {
    label: '商品名',
    prop: 'order',
    minWidth: '200',
    customRender(h, row) {
      return <p>{row['order']?.['orderGoods']?.['skuTitle']}</p>
    }
  },
  {
    label: '总租金',
    prop: 'order',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['order']?.['rentPrice']}</p>
    }
  },
  {
    label: '订单状态',
    prop: 'order',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['order']?.['statusName']}</p>
    }
  },
  {
    label: '是否免押',
    prop: 'enableAlipayFundAuth',
    minWidth: '100',
    customRender(h, row) {
      return (
          <p>
            <el-tag v-show={row['order']?.['enableAlipayFundAuth'] == true} size="small" type="danger">
              是
            </el-tag>
            <el-tag v-show={row['order']?.['enableAlipayFundAuth'] == false} size="small" type="primary">
              否
            </el-tag>
          </p>
      )
    }
  },
  {
    label: '下单时间',
    prop: 'orderTime',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['orderTime']}</p>
    }
  },
  {
    label: '是否注册',
    prop: 'isRegister',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['isRegister'] == 1 ? '已注册' : '新注册'}</p>
    }
  },
  {
    label: '是否在租',
    prop: 'memberType',
    minWidth: '100',
    customRender(h, row) {
      return (
        <p>
          <el-tag v-show={row['memberType'] == 1} size="small" type="success">
            新客
          </el-tag>
          <el-tag v-show={row['memberType'] == 2} size="small" type="primary">
            在租
          </el-tag>
        </p>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  data() {
    return {
      isShow: false, //控制箭头
      dialogVisible: false,
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        orderStatus: [],
        isRegister: '',
        memberType: '',
        leftOrderDate: '',
        rightOrderDate: '',
        keywordValue: '',
        keywordName: '',
        timeSlot: [],
        enableAlipayFundAuth: null,
      },
      channelSearchKw,
      channelOrderStatus,
      memberType,
      isRegister
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const { timeSlot, keywordName, keywordValue, orderStatus, isRegister, memberType, enableAlipayFundAuth } = this.searchData
      let params = { page: this.currentPage, pageSize: this.pageSize, orderStatusList: orderStatus.join(), isRegister, memberType, enableAlipayFundAuth }
      if (timeSlot && timeSlot.length > 0) {
        params.leftOrderDate = timeSlot[0]
        params.rightOrderDate = timeSlot[1]
      }
      if (keywordName) params[keywordName] = keywordValue
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;

  .el-divider--horizontal {
    margin: 0 0 20px;
  }

  .header-search {
    .el-form {
      .el-form-item {
        .el-select {
          width: 280px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 30%;
          }
        }
      }
      .keywords {
        .el-select .el-input {
          width: 100px;
        }

        .el-select {
          width: 100px;
        }
        .input-with-select {
          vertical-align: middle;
        }
        .input-with-select .el-input-group__prepend {
          background-color: #fff;
        }
      }
    }
  }
  .handle-btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
