export default {
  //   渠道管理
  // 渠道列表
  channel_list_add: 'ChannelAdd',
  channel_list_edit: 'ChannelEdit',
  channel_list_del: 'channel_list_del',
  channel_list_status: 'channel_list_status',
  channel_list_copy: 'channel_list_copy',
  channel_list_code: 'channel_list_code',
  channel_list_reset: 'channel_list_reset',
  // 渠道订单
  channel_order_export: 'channel_order_export',
  // 渠道统计
  channel_count_export: 'channel_count_export',

}
